@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Poppins";
  }
}

.bg-gradient-blue {
  background-image: linear-gradient(
    159deg,
    hsl(220.77, 67.83%, 54.9%) 0%,
    hsl(223.88, 65.6%, 56.4%) 12.3%,
    hsl(228.34, 62.59%, 58.13%) 23.4%,
    hsl(234.36, 58.85%, 59.93%) 33.6%,
    hsl(242.31, 55.4%, 60.87%) 42.9%,
    hsl(250.83, 54.35%, 59.42%) 51.3%,
    hsl(259.13, 53.34%, 57.8%) 58.8%,
    hsl(267.07, 52.4%, 56.04%) 65.6%,
    hsl(274.5, 51.59%, 54.16%) 71.7%,
    hsl(281.33, 50.92%, 52.15%) 77.2%,
    hsl(287.45, 50.43%, 50.05%) 82.1%,
    hsl(292.8, 54.61%, 47.87%) 86.4%,
    hsl(297.31, 59.74%, 45.61%) 90.4%,
    hsl(300.94, 66.32%, 43.72%) 93.9%,
    hsl(303.48, 74.88%, 42.67%) 97.1%,
    hsl(305.03, 85.65%, 40.98%) 100%
  );
}

.neumorphic {
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

#chat-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#chat-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#chat-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

/* Handle on hover */
#chat-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #650178;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* width */
#chat-bar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
#chat-bar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#chat-bar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
#chat-bar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
